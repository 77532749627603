import * as React from "react";
import { Header, Container, Segment, Icon } from "semantic-ui-react";
import {withLayout} from "../components/Layout";

const AboutPage = () => {
  return (
    <Container>
      <Segment vertical>
        <Header as="h2">
          <Icon name="info circle" />
          <Header.Content>
            About
          </Header.Content>
        </Header>
      </Segment>
      <Segment vertical>
        <h1>Development Team</h1>
        <ul>
          <li className={"faq"}><a href={"mailto:billmccartney@gmail.com"}>Bill McCartney</a></li>
          <li className={"faq"}><a href={"http://facultyprofile.csuohio.edu/csufacultyprofile/detail.cfm?FacultyID=N_SRIDHAR1"}>Nigamanth Sridhar</a></li>
        </ul>
      </Segment>
    </Container>
  );
};

export default withLayout(AboutPage);
